import { DependencyList, useEffect } from "react";

export const KEY_SPACE = " ";
export const KEY_LEFT = "ArrowLeft";
export const KEY_RIGHT = "ArrowRight";

export function useKeyDown(key: string | number, action: () => void, identityList: DependencyList) {
    useEffect(() => {
        const handler = (e: KeyboardEvent) => {
            if (e.key === key) {
                action();
            }
        };
        document.addEventListener("keydown", handler);
        return () => {
            document.removeEventListener("keydown", handler);
        };

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, identityList);
}
