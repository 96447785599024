import cn from "classnames";
import React, {
    FC,
    forwardRef,
    memo,
    ReactElement,
    Ref,
    useCallback,
    useRef,
    useState,
} from "react";
import { animated, Transition } from "react-spring";
import { useClickOutside } from "../hooks/useClickOutside";
import { ILocale } from "./Backend";

import "./LanguageSelector.css";

interface ILanguageSelectorProps {
    locales: ILocale[];
    selectedLocale: number;
    setSelectedLocale: (id: number) => void;
}

interface IDropdownMenuProps<T> {
    items: T[];
    selected: T | undefined;
    idFunction: (item: T, index: number) => number;
    onSelect: (item: T, index: number) => void;
    render: (item: T) => React.ReactNode;
}

interface IDropDownMenuPropsOut<T> extends IDropdownMenuProps<T> {
    ref?: Ref<HTMLDivElement>;
}

const DropdownMenu: <T>(p: IDropDownMenuPropsOut<T>) => ReactElement<IDropdownMenuProps<T>> | null =
    forwardRef(function DropdownMenu(props, ref) {
        const { items, selected, onSelect, render, idFunction } = props;
        const dropdownElement = (item: typeof items[0], index: number) => {
            const className = cn("dropdown__item__button", {
                "dropdown__item__button--active": item === selected,
            });

            return (
                <li
                    key={idFunction(item, index)}
                    className="dropdown__item"
                >
                    <button
                        className={className}
                        onClick={() => onSelect(item, index)}
                    >
                        {render(item)}
                    </button>
                </li>
            );
        };

        return (
            <div
                className="dropdown"
                ref={ref}
            >
                <ul className="dropdown__item-list">
                    {items.map((item, index) => dropdownElement(item, index))}
                </ul>
            </div>
        );
    });

const getId = (_: ILocale, index: number) => index;
const renderLocale = (locale: ILocale) => locale.language;

export const LanguageSelector: FC<ILanguageSelectorProps> = memo(function LanguageSelector(props) {
    const { locales, selectedLocale, setSelectedLocale } = props;
    const menuRef = useRef(null);
    const [showDropDown, setShowDropDown] = useState(false);

    useClickOutside(
        menuRef,
        () => {
            if (showDropDown) {
                setShowDropDown(false);
            }
        },
        [showDropDown]
    );

    const selected = locales[selectedLocale];
    const setSelected = useCallback(
        (_: ILocale, index: number) => {
            setSelectedLocale(index);
            setShowDropDown(false);
        },
        [setSelectedLocale]
    );

    const toggleShowMenu = useCallback(() => {
        setTimeout(() => setShowDropDown(!showDropDown));
    }, [showDropDown]);

    const languageMenuButtonClassnames = cn("language-menu__toggle-button", {
        "language-menu__toggle-button--open": showDropDown,
    });

    return (
        <div className="language-menu">
            <button
                className={languageMenuButtonClassnames}
                onClick={toggleShowMenu}
            >
                {selected == null ? "Select language" : selected.language}
            </button>
            <Transition
                native={true}
                items={showDropDown}
                config={{ duration: 200 }}
                from={{ opacity: 0, transform: "scale(0.3)", transformOrigin: "top right" }}
                enter={{ opacity: 1, transform: "scale(1)", transformOrigin: "top right" }}
                leave={{ opacity: 0, transform: "scale(0.3)", transformOrigin: "top right" }}
            >
                {(style, show) =>
                    show ? (
                        <animated.div style={style}>
                            <DropdownMenu
                                ref={menuRef}
                                items={locales}
                                selected={selected}
                                onSelect={setSelected}
                                idFunction={getId}
                                render={renderLocale}
                            />
                        </animated.div>
                    ) : (
                        <></>
                    )
                }
            </Transition>
        </div>
    );
});
