import { useEffect, useState } from "react";

export function useLocationHash(): string {
    const getHash = () => document.location.hash.substring(1);

    const [hash, setHash] = useState(getHash());
    useEffect(() => {
        const listener: EventListener = () => {
            setHash(getHash);
        };
        window.addEventListener("hashchange", listener);
        return () => {
            window.removeEventListener("hashchange", listener);
        };
    }, []);

    return hash;
}
