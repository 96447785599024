import { ISubtitleState } from "./Reducer";

export function canMoveLeft(state: ISubtitleState): boolean {
    return state.selectedRow != null && state.selectedRow > 1;
}

export function canMoveRight(state: ISubtitleState): boolean {
    return (
        state.selectedRow != null &&
        state.show != null &&
        state.selectedRow + 1 < state.show.parts[state.currentState.part].rows.length
    );
}
