import { useEffect, useRef, useState } from "react";

export interface ILocale {
    id: string;
    country: string;
    language: string;
}

export interface IPart {
    names: string[];
    rows: string[][];
}

export interface IShow {
    locales: ILocale[];
    names: string[];
    parts: IPart[];
}

export interface ICurrentState {
    mute: boolean;
    onHold: boolean;
    part: number;
    row: number;
}

export interface IShowWithState {
    hash: string;
    show: IShow;
    state: ICurrentState;
}

export interface IStartShowEvent {
    type: "IStartShowEvent";
    data: IShowWithState;
}

export interface IStopShowEvent {
    type: "IStopShowEvent";
    data: undefined;
}

export interface ISetCurrentStateEvent {
    type: "ISetCurrentStateEvent";
    data: ICurrentState;
}

export type SocketEvent = IStartShowEvent | IStopShowEvent | ISetCurrentStateEvent;

const RETRY_COUNT = 10;

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL ?? "ws://localhost:8080";

export function useSubscription(
    key: [string, string],
    onNext: (newState: SocketEvent) => void,
    onError: () => void
) {
    const [org, id] = key;
    const retryRef = useRef(RETRY_COUNT);
    const [shouldReopen, setShouldReopen] = useState(true);

    useEffect(() => {
        retryRef.current = RETRY_COUNT;
    }, [org, id]);

    useEffect(() => {
        const ws = new WebSocket(`${BACKEND_URL}/ws/${org}/stream/${id}?format=json`);
        ws.onopen = () => {
            retryRef.current = RETRY_COUNT;
        };
        ws.onmessage = (ev) => {
            onNext(JSON.parse(ev.data));
        };

        ws.onerror = () => {
            onError();
        };

        ws.onclose = () => {
            retryRef.current -= 1;
            if (retryRef.current > 0) {
                setShouldReopen(!shouldReopen);
            } else {
                onError();
            }
        };

        return () => {
            ws.close();
        };
    }, [org, id, shouldReopen, onNext, onError]);
}
