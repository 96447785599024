import React, { FC, memo } from "react";

import "./ControlPlanes.css";

interface IControlPlaneProps {
    onLeftClick: () => void;
    onRightClick: () => void;
    leftEnabled: boolean;
    rightEnabled: boolean;
}

export const ControlPanes: FC<IControlPlaneProps> = memo(function ControlPanes(props) {
    const { leftEnabled, rightEnabled, onLeftClick, onRightClick } = props;
    return (
        <>
            {leftEnabled && (
                <div
                    role="button"
                    className="control-plane-left"
                    onClick={onLeftClick}
                />
            )}
            {rightEnabled && (
                <div
                    role="button"
                    className="control-plane-right"
                    onClick={onRightClick}
                />
            )}
        </>
    );
});
