import React, { FC } from "react";
import { SubtitlesScreen } from "./Subtitles/SubtitlesScreen";

const App: FC = () => (
    <>
        <SubtitlesScreen />
    </>
);

export default App;
