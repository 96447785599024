import React, { FC, memo } from "react";
import { animated, Transition } from "react-spring";

import "./RecenterButton.css";

const Crosshair: FC = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 48 48"
    >
        <path
            d="M0 0h48v48h-48z"
            fill="none"
        />
        <path
            d={
                "M24 16c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm17.88 " +
                "6c-.92-8.34-7.54-14.96-15.88-15.88v-4.12h-4v4.12c-8.34.92-14.96 7.54-15.88 " +
                "15.88h-4.12v4h4.12c.92 8.34 7.54 14.96 15.88 15.88v4.12h4v-4.12c8.34-.92 " +
                "14.96-7.54 15.88-15.88h4.12v-4h-4.12zm-17.88 16c-7.73 0-14-6.27-14-14s6.27-14 " +
                "14-14 14 6.27 14 14-6.27 14-14 14z"
            }
        />
    </svg>
);

interface IRecenterButtonProps {
    visible: boolean;
    onClick: () => void;
}

export const RecenterButton: FC<IRecenterButtonProps> = memo(function RecenterButton({
    visible,
    onClick,
}) {
    return (
        <Transition
            native={true}
            items={
                visible && (
                    <button
                        onClick={onClick}
                        className="recenter-button"
                    >
                        <Crosshair />
                    </button>
                )
            }
            from={{ opacity: 0 }}
            enter={{ opacity: 1 }}
            leave={{ opacity: 0 }}
        >
            {(style, button) => (
                <animated.div
                    style={style}
                    className="recenter-button-container"
                >
                    {button}
                </animated.div>
            )}
        </Transition>
    );
});
