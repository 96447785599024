import { DependencyList, RefObject, useEffect } from "react";
export function useClickOutside(
    elementReference: RefObject<HTMLElement>,
    onClick: (e: Event) => void,
    identityList: DependencyList
) {
    useEffect(() => {
        const eventListener = (e: Event) => {
            const el = elementReference.current;
            if (el == null || el.contains == null || el.contains(e.target as HTMLElement)) {
                return;
            }

            onClick(e);
        };
        window.addEventListener("click", eventListener);
        return () => {
            window.removeEventListener("click", eventListener);
        };

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, identityList);
}
