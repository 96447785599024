import React, { FC, memo, useEffect, useMemo, useReducer } from "react";
import { animated, Transition } from "react-spring";
import { KEY_LEFT, KEY_RIGHT, KEY_SPACE, useKeyDown } from "../hooks/useKeyDown";
import { useLocationHash } from "../hooks/useLocationHash";
import { useThrottle } from "../hooks/useThrottle";
import { SocketEvent, useSubscription } from "./Backend";
import { ControlPanes } from "./ControlPlanes";
import { LanguageSelector } from "./LanguageSelector";
import { RecenterButton } from "./RecenterButton";
import {
    failLoading,
    moveLeft,
    moveRight,
    nextSocketEvent,
    recenter,
    setLocale,
    startLoading,
} from "./state/Actions";
import { defaultState, Direction, reducer } from "./state/Reducer";
import { canMoveLeft, canMoveRight } from "./state/Selectors";

import "./Subtitles.css";

const transitionConfig = { duration: 60 };

const animation = {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
};

const directionAnimation = {
    [Direction.RIGHT]: {
        from: { opacity: 0, transform: "translate(100vw, 0)" },
        enter: { opacity: 1, transform: "translate(0, 0)" },
        leave: { opacity: 0, transform: "translate(-100vw, 0)" },
    },
    [Direction.LEFT]: {
        from: { opacity: 0, transform: "translate(-100vw, 0)" },
        enter: { opacity: 1, transform: "translate(0, 0)" },
        leave: { opacity: 0, transform: "translate(100vw, 0)" },
    },
};

function animationByDirection(direction?: Direction) {
    return direction === undefined ? animation : directionAnimation[direction];
}

interface ISubtitlesProps {
    text: string;
    direction?: Direction;
}

const Subtitles: FC<ISubtitlesProps> = memo(function Subtitles({ text, direction }) {
    return (
        <div className="subtitle-container">
            <Transition
                native={true}
                config={transitionConfig}
                items={text}
                {...animationByDirection(direction)}
            >
                {(style, line) => (
                    <animated.div
                        style={style}
                        className="text-translation"
                    >
                        {line}
                    </animated.div>
                )}
            </Transition>
        </div>
    );
});

export const SubtitlesScreen: FC = () => {
    const hash = useLocationHash();
    const key = useMemo<[string, string]>(() => {
        const [org, id] = (hash || "").split("/");
        return [org || "", id || ""];
    }, [hash]);

    const [state, dispatch] = useReducer(reducer, defaultState);

    const action = useMemo(
        () => ({
            nextSocketEvent: (s: SocketEvent) => dispatch(nextSocketEvent(s)),
            setLocale: (s: number) => dispatch(setLocale(s)),
            moveLeft: () => dispatch(moveLeft),
            moveRight: () => dispatch(moveRight),
            recenter: () => dispatch(recenter),
            startLoading: () => dispatch(startLoading),
            failLoading: () => dispatch(failLoading),
        }),
        []
    );

    useEffect(() => {
        action.startLoading();
    }, [key, action]);
    useSubscription(key, action.nextSocketEvent, action.failLoading);

    useKeyDown(KEY_LEFT, action.moveLeft, []);
    useKeyDown(KEY_RIGHT, action.moveRight, []);
    useKeyDown(KEY_SPACE, action.recenter, []);

    const {
        loadingError,
        showLoading,
        currentState,
        selectedRow,
        selectedLocale,
        show,
        direction,
        end,
    } = state;

    const text = useThrottle(
        show != null && selectedRow != null && selectedLocale != null
            ? show.parts[currentState.part].rows[selectedRow][selectedLocale]
            : "",
        300
    );

    if (loadingError) {
        return <div className="text-info">Unable to connect to backend</div>;
    }

    if (showLoading) {
        return <div className="text-info">Loading...</div>;
    }

    if (end) {
        return (
            <div className="text-info">
                There is nothing going on right now, please come back later.
            </div>
        );
    }

    if (currentState.mute || selectedLocale == null) {
        return <div />;
    }

    if (currentState.onHold || selectedRow == null) {
        return (
            <>
                <div className="text-info">{selectedLocale ? show?.names[selectedLocale] : ""}</div>
                <LanguageSelector
                    locales={show?.locales ?? []}
                    selectedLocale={selectedLocale}
                    setSelectedLocale={action.setLocale}
                />
            </>
        );
    }

    return (
        <>
            <Subtitles
                text={text}
                direction={direction}
            />
            <ControlPanes
                leftEnabled={canMoveLeft(state)}
                rightEnabled={canMoveRight(state)}
                onLeftClick={action.moveLeft}
                onRightClick={action.moveRight}
            />
            <LanguageSelector
                locales={show?.locales ?? []}
                selectedLocale={selectedLocale}
                setSelectedLocale={action.setLocale}
            />
            <RecenterButton
                visible={currentState.row !== selectedRow}
                onClick={action.recenter}
            />
        </>
    );
};
