import { ICurrentState, IShow } from "../Backend";
import { canMoveLeft, canMoveRight } from "./Selectors";
import { Action } from "./Actions";

export enum Direction {
    LEFT,
    RIGHT,
}

export interface ISubtitleState {
    readonly show?: IShow;
    readonly showLoading: boolean;
    readonly loadingError: boolean;
    readonly currentState: ICurrentState;
    readonly selectedLocale?: number;
    readonly selectedRow?: number;
    readonly direction?: Direction;
    readonly end: boolean;
}

export const defaultState: ISubtitleState = {
    showLoading: true,
    loadingError: false,
    currentState: { part: 0, row: 0, mute: true, onHold: true },
    end: false,
};

export function reducer(state: ISubtitleState, action: Action): ISubtitleState {
    switch (action.type) {
        case "StartLoading": {
            return { ...state, showLoading: true, loadingError: false };
        }
        case "NextSocketEvent": {
            const payload = action.data;
            switch (payload.type) {
                case "IStartShowEvent": {
                    return {
                        show: payload.data.show,
                        showLoading: false,
                        loadingError: false,
                        currentState: payload.data.state,
                        selectedLocale: 0,
                        selectedRow: payload.data.state.row,
                        direction: undefined,
                        end: false,
                    };
                }
                case "IStopShowEvent": {
                    return {
                        ...state,
                        showLoading: false,
                        loadingError: false,
                        end: true,
                    };
                }
                case "ISetCurrentStateEvent": {
                    const { selectedRow, currentState, show } = state;
                    const newState = payload.data;
                    const moveSelectedRow =
                        selectedRow === currentState.row ||
                        selectedRow === undefined ||
                        currentState.part !== newState.part;

                    const legal =
                        show != null &&
                        newState.part >= 0 &&
                        newState.part < show.parts.length &&
                        newState.row >= 0 &&
                        newState.row < show.parts[newState.part].rows.length;

                    if (!legal) {
                        return state;
                    }

                    return moveSelectedRow
                        ? {
                              ...state,
                              currentState: newState,
                              selectedRow: newState.row,
                              direction: selectedRow == null ? undefined : Direction.RIGHT,
                          }
                        : { ...state, currentState: newState };
                }
            }
            return state;
        }
        case "FailLoading": {
            return { ...state, showLoading: false, loadingError: true };
        }
        case "MoveLeft": {
            return state.selectedRow !== undefined && canMoveLeft(state)
                ? { ...state, selectedRow: state.selectedRow - 1, direction: Direction.LEFT }
                : state;
        }
        case "MoveRight": {
            return state.selectedRow !== undefined && canMoveRight(state)
                ? { ...state, selectedRow: state.selectedRow + 1, direction: Direction.RIGHT }
                : state;
        }
        case "Recenter": {
            const {
                selectedRow,
                currentState: { row },
            } = state;
            return selectedRow !== undefined
                ? {
                      ...state,
                      selectedRow: row,
                      direction: row < selectedRow ? Direction.LEFT : Direction.RIGHT,
                  }
                : state;
        }
        case "SetLocale": {
            return { ...state, selectedLocale: action.data };
        }
        default:
            return state;
    }
}
