import { SocketEvent } from "../Backend";

type SimpleAction<T extends string> = {
    type: T;
};

type ComplexAction<T extends string, D> = {
    type: T;
    data: D;
};

type StartLoading = SimpleAction<"StartLoading">;
type FailLoading = SimpleAction<"FailLoading">;
type MoveLeft = SimpleAction<"MoveLeft">;
type MoveRight = SimpleAction<"MoveRight">;
type Recenter = SimpleAction<"Recenter">;
type NextSocketEvent = ComplexAction<"NextSocketEvent", SocketEvent>;
type SetLocale = ComplexAction<"SetLocale", number>;

export const startLoading: StartLoading = { type: "StartLoading" };
export const failLoading: FailLoading = { type: "FailLoading" };
export const moveLeft: MoveLeft = { type: "MoveLeft" };
export const moveRight: MoveRight = { type: "MoveRight" };
export const recenter: Recenter = { type: "Recenter" };
export const nextSocketEvent = (data: SocketEvent): NextSocketEvent => ({
    type: "NextSocketEvent",
    data,
});
export const setLocale = (data: number): SetLocale => ({ type: "SetLocale", data });

export type Action =
    | StartLoading
    | FailLoading
    | MoveLeft
    | MoveRight
    | Recenter
    | NextSocketEvent
    | SetLocale;
